import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-client-registration',
  templateUrl: './client-registration.component.html',
  styleUrls: ['./client-registration.component.sass']
})
export class ClientRegistrationComponent implements OnInit {

  registration: FormGroup;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.buildForm();
  }
  buildForm(){
    this.registration = this.formBuilder.group([{
      firstName:['',[Validators.required]],
      lastName: ['',[Validators.required]],
      cellphone: ['',[Validators.required]],
      email: ['',[Validators.required]],
      tNc: ['',[Validators.required]]
    }]);
  }

}
